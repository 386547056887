import { FormEvent, useState } from "react"
import styled from "styled-components"

import { CallAssistUpsellPopupButton } from "src/components/SettingsProfiles/CallAssistUpsellPopupButton"
import {
  booleanToStatus,
  usePostTrackNoiseSettingsAutocallEditInteractedEvent,
  usePostTrackNoiseSettingsAutocallToggledEvent,
  usePostTrackNoiseSettingsCallAssistToggledEvent,
  usePostTrackNoiseSettingsFlashAndSoundToggledEvent,
  usePostTrackNoiseSettingsManageDialogCancelledEvent,
  usePostTrackNoiseSettingsManageDialogSavedEvent,
  usePostTrackNoiseSettingsSMSToGuestEditInteractedEvent,
  usePostTrackNoiseSettingsSMSToGuestToggledEvent,
  variantToAlertLevel,
} from "src/data/analytics/queries/settingsAnalyticsQueries"
import { useGetCallAssistActive } from "src/data/callAssist/queries/callAssistQueries"
import { usePatchProfile } from "src/data/homeProfiles/queries/HomeProfilesQueries"
import { usePatchOrganization } from "src/data/organizations/queries/organizationQueries"
import {
  createOrganizationRequest,
  createProfileRequest,
} from "src/data/profileSettings/noiseAlertSettings"
import {
  TNoiseAlertSettings,
  TNoiseAlertVariant,
} from "src/data/profileSettings/noiseAlertTypes"
import { useIsValueDirty } from "src/hooks/useIsValueDirty"
import { langKeys } from "src/i18n/langKeys"
import { TTranslateFunction, useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { DiscardChangesDialog } from "src/ui/Dialog/DiscardChangesDialog"
import { MDialog } from "src/ui/Dialog/MDialog"
import { MDivider } from "src/ui/Divider/Divider"
import { MInfo } from "src/ui/Info/MInfo"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

import { NoiseAlertTextArea } from "./NoiseAlertTextArea"
import { NoiseAlertToggle } from "./NoiseAlertToggle"

const FORM_ID = "noise-alert-form"

export function NoiseAlertDialog({
  onClose,
  settings,
  variant,
  open,
}: {
  onClose: () => void
  settings: TNoiseAlertSettings
  variant: TNoiseAlertVariant
  open: boolean
}) {
  const [showConfirm, setShowConfirm] = useState(false)

  const smsToGuest = useIsValueDirty({ initial: settings.smsEnabled })
  const autocall = useIsValueDirty({ initial: settings.autocallEnabled })
  const smsMessage = useIsValueDirty({ initial: settings.smsContent })
  const autocallMessage = useIsValueDirty({ initial: settings.autocallContent })
  const flashAndSound = useIsValueDirty({ initial: settings.flashEnabled })
  const callAssistEnabled = useIsValueDirty({
    initial: settings.callAssistEnabled ?? false,
  })

  const flashAndSoundChangeTrackingEvent =
    usePostTrackNoiseSettingsFlashAndSoundToggledEvent()
  const smsToGuestChangeTrackingEvent =
    usePostTrackNoiseSettingsSMSToGuestToggledEvent()
  const autoCallInteractedTrackingEvent =
    usePostTrackNoiseSettingsAutocallToggledEvent()
  const saveTrackingEvent = usePostTrackNoiseSettingsManageDialogSavedEvent()
  const cancelTrackingEvent =
    usePostTrackNoiseSettingsManageDialogCancelledEvent()
  const smsMessageInteractedTrackingEvent =
    usePostTrackNoiseSettingsSMSToGuestEditInteractedEvent()

  const autoCallMessageChangeTrackingEvent =
    usePostTrackNoiseSettingsAutocallEditInteractedEvent()

  function handleAutocallMessageFocus() {
    autoCallMessageChangeTrackingEvent.mutate({
      alert_level: variantToAlertLevel[variant],
    })
  }

  function handleSmsMessageFocus() {
    smsMessageInteractedTrackingEvent.mutate({
      alert_level: variantToAlertLevel[variant],
    })
  }

  function handleDiscardConfirmed() {
    cancelTrackingEvent.mutate({
      alert_level: variantToAlertLevel[variant],
    })
    setShowConfirm(false)
    onClose()
  }

  function handleFlashAndSoundChange(value: boolean) {
    flashAndSoundChangeTrackingEvent.mutate({
      alert_level: variantToAlertLevel[variant],
      new_status: booleanToStatus(value),
    })
    flashAndSound.setValue(value)
  }

  function handleSmsChange(value: boolean) {
    smsToGuestChangeTrackingEvent.mutate({
      alert_level: variantToAlertLevel[variant],
      new_status: booleanToStatus(value),
    })
    smsToGuest.setValue(value)
  }

  const dirty = [
    smsToGuest,
    autocall,
    smsMessage,
    autocallMessage,
    flashAndSound,
    callAssistEnabled,
  ].some((item) => item.isDirty)

  const { langKeys, t } = useTranslate()

  const [editBlocked, setEditBlocked] = useState<{
    title: string
    body: string
  } | null>(null)

  const patchProfile = usePatchProfile()
  const patchOrganization = usePatchOrganization()
  const callAssistState = useGetCallAssistActive()

  function handleAutoCallChange(value: boolean) {
    if (value && callAssistEnabled.value) {
      setEditBlocked({
        title: t(langKeys.profiles_cant_turn_on_autocall_title),
        body: t(langKeys.profiles_cant_turn_on_autocall_body),
      })
    } else {
      autocall.setValue(value)
      autoCallInteractedTrackingEvent.mutate({
        alert_level: variantToAlertLevel[variant],
        new_status: booleanToStatus(value),
      })
    }
  }

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    e.stopPropagation()

    saveTrackingEvent.mutate({
      alert_level: variantToAlertLevel[variant],
    })

    const profileRequest = createProfileRequest(
      variant,
      smsToGuest.value,
      autocall.value,
      flashAndSound.value,
      callAssistEnabled.value
    )

    const organizationRequest = createOrganizationRequest(
      variant,
      smsMessage.value,
      autocallMessage.value
    )

    const profileMutation = patchProfile.mutateAsync({
      data: profileRequest,
      id: settings.profileId,
    })

    const patchMutation = patchOrganization.mutateAsync({
      orgId: settings.orgId,
      body: organizationRequest,
    })

    Promise.all([profileMutation, patchMutation]).then(() => onClose())
  }

  function handleClose() {
    if (dirty) {
      setShowConfirm(true)
    } else {
      onClose()
    }
  }

  const error =
    patchProfile.error || patchOrganization.error
      ? t(langKeys.failed_to_save)
      : undefined

  return (
    <MDialog
      open={open}
      onClose={handleClose}
      description={t(langKeys.settings_noise_alerts_description)}
      hideClose
      formId={FORM_ID}
      confirmLabel={t(langKeys.save)}
      title={title(t, variant)}
      loading={
        patchProfile.isLoading ||
        patchOrganization.isLoading ||
        callAssistState.isInitialLoading
      }
      error={error}
    >
      <MDialog
        title={editBlocked?.title}
        open={!!editBlocked}
        onClose={() => setEditBlocked(null)}
      >
        <MText>{editBlocked?.body}</MText>
      </MDialog>
      {showConfirm && (
        <DiscardChangesDialog
          open={showConfirm}
          onClose={() => {
            setShowConfirm(false)
          }}
          onConfirm={handleDiscardConfirmed}
        />
      )}
      <Form id={FORM_ID} onSubmit={handleSubmit}>
        <NoiseAlertToggle
          setState={handleSmsChange}
          titleTooltipComponent={
            <InfoTooltip
              tooltip={t(langKeys.settings_noise_alerts_sms_description)}
            />
          }
          title={t(langKeys.settings_noise_alerts_sms_title)}
          state={smsToGuest.value}
        >
          <NoiseAlertTextArea
            required={smsToGuest.value}
            title={t(langKeys.settings_noise_alerts_sms_message)}
            subtitle={t(
              langKeys.profiles_noise_monitoring_sms_guest_message_description
            )}
            setValue={smsMessage.setValue}
            value={smsMessage.value}
            onFocus={handleSmsMessageFocus}
          />
        </NoiseAlertToggle>

        <MDivider />

        <NoiseAlertToggle
          setState={handleAutoCallChange}
          titleTooltipComponent={
            <InfoTooltip
              tooltip={t(langKeys.settings_noise_alerts_autocall_description)}
            />
          }
          title={t(langKeys.settings_noise_alerts_autocall_title)}
          state={autocall.value}
          toggleTooltipText={
            callAssistEnabled.value
              ? t(langKeys.profiles_cant_turn_on_autocall_body)
              : undefined
          }
        >
          <NoiseAlertTextArea
            required={autocall.value}
            title={t(langKeys.settings_noise_alerts_autocall_message)}
            subtitle={t(
              langKeys.profiles_noise_monitoring_sms_guest_message_description
            )}
            setValue={autocallMessage.setValue}
            value={autocallMessage.value}
            onFocus={handleAutocallMessageFocus}
          />
        </NoiseAlertToggle>

        <MDivider />

        <NoiseAlertToggle
          setState={handleFlashAndSoundChange}
          titleTooltipComponent={
            <InfoTooltip
              tooltip={t(
                langKeys.settings_noise_alerts_flash_and_sound_description
              )}
            />
          }
          title={t(langKeys.settings_noise_alerts_flash_and_sound_title)}
          state={flashAndSound.value}
        />

        {variant === "third_alert" && (
          <CallAssist
            state={callAssistEnabled.value}
            setState={callAssistEnabled.setValue}
            autocallEnabled={autocall.value}
            setEditBlocked={setEditBlocked}
            canUseCallAssist={callAssistState.callAssistIsActive}
          />
        )}
      </Form>
    </MDialog>
  )
}

function CallAssist({
  setState,
  state,
  autocallEnabled,
  canUseCallAssist,
  setEditBlocked,
}: {
  state: boolean
  setState: (b: boolean) => void
  autocallEnabled: boolean
  canUseCallAssist: boolean
  setEditBlocked: (blocked: { title: string; body: string }) => void
}) {
  const { t, langKeys } = useTranslate()
  const { navigate } = useRouter()
  const callAssistChangEvent = usePostTrackNoiseSettingsCallAssistToggledEvent()

  const tooltip = canUseCallAssist ? (
    <InfoTooltip
      tooltip={
        t(langKeys.settings_noise_alerts_call_assist_trained_expert) + "."
      }
    />
  ) : (
    <CallAssistUpsellPopupButton context="noise_settings" />
  )
  const toggleTooltip = autocallEnabled
    ? t(langKeys.profiles_cant_turn_on_call_assist_body)
    : undefined

  function handleToggle(value: boolean) {
    if (autocallEnabled) {
      setEditBlocked({
        title: t(langKeys.profiles_cant_turn_on_call_assist_title),
        body: t(langKeys.profiles_cant_turn_on_call_assist_body),
      })
    } else if (canUseCallAssist) {
      callAssistChangEvent.mutate({
        alert_level: "third",
        new_status: booleanToStatus(value),
      })
      setState(value)
    } else {
      callAssistChangEvent.mutate({
        alert_level: "third",
        new_status: "activation",
      })
      navigate(Routes.CallAssistActivate.location())
    }
  }

  return (
    <>
      <MDivider />
      <NoiseAlertToggle
        setState={handleToggle}
        titleTooltipComponent={tooltip}
        title={t(langKeys.call_assist)}
        state={state}
        toggleTooltipText={toggleTooltip}
      />
    </>
  )
}

function title(t: TTranslateFunction, variant: TNoiseAlertVariant) {
  const titles: Record<TNoiseAlertVariant, string> = {
    first_alert: t(langKeys.settings_noise_alerts_first_alert_title),
    second_alert: t(langKeys.settings_noise_alerts_second_alert_title),
    third_alert: t(langKeys.settings_noise_alerts_third_alert_title),
  }

  return titles[variant]
}

function InfoTooltip({ tooltip }: { tooltip: React.ReactNode }) {
  return (
    <MInfo
      iconProps={{
        type: "info",
      }}
      content={tooltip}
      wrapperProps={{ style: { display: "inline" } }}
    />
  )
}

const Form = styled.form`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: ${spacing.M};
`
